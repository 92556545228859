exports.components = {
  "component---src-name-my-car-index-js": () => import("./../../../src/nameMyCar/index.js" /* webpackChunkName: "component---src-name-my-car-index-js" */),
  "component---src-name-my-car-name-my-car-result-js": () => import("./../../../src/nameMyCar/name-my-car-result.js" /* webpackChunkName: "component---src-name-my-car-name-my-car-result-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-book-a-service-js": () => import("./../../../src/pages/book-a-service.js" /* webpackChunkName: "component---src-pages-book-a-service-js" */),
  "component---src-pages-car-servicing-js": () => import("./../../../src/pages/car-servicing.js" /* webpackChunkName: "component---src-pages-car-servicing-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-service-centres-js": () => import("./../../../src/pages/service-centres.js" /* webpackChunkName: "component---src-pages-service-centres-js" */),
  "component---src-templates-content-page-js": () => import("./../../../src/templates/content-page.js" /* webpackChunkName: "component---src-templates-content-page-js" */),
  "component---src-templates-service-centre-js": () => import("./../../../src/templates/service-centre.js" /* webpackChunkName: "component---src-templates-service-centre-js" */),
  "component---src-templates-service-page-js": () => import("./../../../src/templates/service-page.js" /* webpackChunkName: "component---src-templates-service-page-js" */)
}

